// import React, { Component } from "react";
// import { SkillsList } from "../database/data";

// export class Skills extends Component {
//   render() {
//     return (
//       <section id="skills" className="skills section-bg">
//         <div className="container">
//           <div className="section-title">
//             <h2>Skills</h2>
//           </div>
//           <div className="row skills-content">
//             <div className="col-lg-12" data-aos="fade-up">
//               <div className="row" >
//                 {SkillsList.map((skills, index) => {
//                   return (
//                     <div
//                       className="progress col-lg-6"
//                       data-aos="fade-up"
//                       data-aos-delay="150"
//                       key={skills.id}
//                     >
//                       <span className="skill">
//                         {skills.name}{" "}
//                         <i className="val">{skills.skill + "%"}</i>
//                       </span>
//                       <div className="progress-bar-wrap">
//                         <div
//                           className={skills.class}
//                           role="progressbar"
//                           aria-valuenow={skills.skill}
//                           aria-valuemin="0"
//                           aria-valuemax="100"
//                           style={{ width: skills.style }}
//                         ></div>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     );
//   }
// }

// export default Skills;
import React from "react";
import { SkillsList } from "../database/data";
import "../assets/css/skills.css"; // Assuming you have a separate CSS file for styles


// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Skills = () => {
  // Shuffle the SkillsList array
  const shuffledSkillsList = shuffleArray([...SkillsList]);
  return (
    <section id="skills" className="skills section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Skills</h2>
        </div>
        <div className="row skills-content">
          {shuffledSkillsList.map((skill, index) => (
            <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={index}>
              <div className="icon-box" data-aos="fade-up">
                <div className="icon" data-aos="fade-up">
                  <img
                    src={skill.imageLink}
                    alt={skill.name}
                    title={skill.name}
                    className="img-fluid"
                    style={{ width: "90px", height: "90px" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
